import React, { useState } from "react"
import { navigate, Link } from "gatsby"
import useAuth from "../hooks/useAuth"
import { FaLock, FaUserCircle } from "react-icons/fa"

const Login = ({ redirect }) => {
  const { login } = useAuth()
  const [identifier, setIdentifier] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [alert, setAlert] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      await login({ identifier, password })
      navigate(redirect)
    } catch (e) {
      const {
        response: {
          data: {
            message: [
              {
                messages: [error],
              },
            ],
          },
        },
      } = e
      const { message: msg } = error
      setAlert(true)
      setError(msg)
    }
  }

  return (
    <>
      {error.length > 1 && (
        <div className={alert ? "mb-3" : "is-hidden"}>
          <div class="notification is-danger">
            <button class="delete" onClick={() => setAlert(false)}>
              Close notification
            </button>
            {error}
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="field">
          <label className="label" htmlFor="username">
            Username
          </label>
          <div className="control has-icons-left">
            <input
              onChange={(e) => {
                setIdentifier(e.target.value)
              }}
              value={identifier}
              id="username"
              type="text"
              placeholder="Your Username"
              className="input"
            />
            <span className="icon is-small is-left">
              <FaUserCircle />
            </span>
          </div>
          <p className="help">This was sent when you were invited.</p>
        </div>
        <div className="field">
          <label className="label" htmlFor="password">
            Password
          </label>
          <div className="control has-icons-left">
            <input
              onChange={(e) => {
                setPassword(e.target.value)
              }}
              value={password}
              id="password"
              type="password"
              placeholder="***************"
              className="input"
            />
            <span className="icon is-small is-left">
              <FaLock />
            </span>
          </div>
        </div>
        <div className="field is-grouped is-grouped-centered">
          <p className="control">
            <button className="button is-primary" type="submit">
              Login
            </button>
          </p>
          <p className="control">
            <Link to="/">
              <button className="button is-light">Cancel</button>
            </Link>
          </p>
        </div>
      </form>
    </>
  )
}

export default Login
