import React from "react"
import Login from "../components/loginform"
import Layout from "../components/layout"
import styled from "styled-components"

const StyledCard = styled.div`
  border-radius: 2rem;
  @media (min-width: 769px) {
    margin-top: 10rem;
  }
`

const LoginPage = ({ location }) => {
  const { state: routeState } = location
  const redirect = !routeState
    ? "/app"
    : routeState.redirect === "app"
    ? "/app"
    : `/app/${routeState.redirect}`

  return (
    <Layout>
      <div className="columns is-centered is-vcentered mx-1">
        <div className="column is-4">
          <StyledCard className="card">
            <div className="card-content">
              <h1 className="title has-text-centered">Login</h1>
              <Login redirect={redirect} />
            </div>
          </StyledCard>
        </div>
      </div>
    </Layout>
  )
}

export default LoginPage
